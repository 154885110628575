import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  List, ListItem, ListItemAvatar, ListItemText, ListItemIcon, Typography, Button, Box, TextField, IconButton, AppBar, Toolbar, useMediaQuery,
  Drawer, Container, Fade, Grow, Grid, Card, CardActionArea, CardContent, CardMedia, FormControlLabel, Radio
} from '@mui/material';
import { ThemeProvider, createTheme, useTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { list, getUrl } from 'aws-amplify/storage';
import { signOut } from 'aws-amplify/auth';
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Message as MessageIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
  PhotoLibrary as PhotoLibraryIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

export function Gallery() {
  const [categories, setCategories] = useState([]);
  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initialDisplayCount = isMobile ? 4 : 6;

  useEffect(() => {
    fetchCategories();
  }, []);
  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const result = await list({
        prefix: 'Gallery/',
        options: {
          listAll: true
        }
      });
      // const result = await list({ prefix: 'Gallery/' });
      console.log(result)
      const filteredCategories = result.items.filter((item) => item.key.endsWith('/'));
      const categoriesWithInfo = await Promise.all(
        filteredCategories.map(async (category) => {
          const { key, lastModified } = category;
          const imageUrl = await getUrl({
            key: key + 'cover.jpg',
            options: {
              accessLevel: 'guest',
            },
          });
          const imageCount = await getImageCount(key);
          let eventDate = lastModified; // default to lastModified
          if (key.includes("event_date=")) {
            const dateMatch = key.match(/event_date=([^/]+)/);
            if (dateMatch) {
              eventDate = new Date(dateMatch[1]);
            }
          }
          return {
            key,
            lastModified,
            imageUrl: imageUrl.url.href,
            imageCount,
            description: 'Sample description',
            eventDate: eventDate
          };
        })
      );
      // Sort categories by date, newest first
      const sortedCategories = categoriesWithInfo.sort((a, b) => {
        return new Date(b.eventDate) - new Date(a.eventDate);
      });
      setCategories(sortedCategories);
      setDisplayedCategories(sortedCategories.slice(0, initialDisplayCount)); // Initial load of 12 items
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const getImageCount = async (categoryKey) => {
    try {
      const result = await list({ prefix: categoryKey });
      return result.items.filter(item => !item.key.endsWith('/')).length;
    } catch (error) {
      console.error('Error fetching image count:', error);
      return 0;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const filteredCategories = useMemo(() => {
    const searchTermLower = searchTerm.toLowerCase();

    // Helper function to extract event name
    const getEventName = (key) => {
      if (key.includes("event_name=")) {
        return key
          .split("/")[1]
          .split("event_name=")[1]
          .split("event_date=")[0]
          .trim()
          .toLowerCase();
      }
      return key.split("/")[1].toLowerCase();
    };

    // Helper function to extract and format event date
    const getEventDate = (key) => {
      if (key.includes("event_date=")) {
        const dateMatch = key.match(/event_date=([^/]+)/);
        if (dateMatch) {
          const dateStr = dateMatch[1];
          const date = new Date(dateStr);
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).toLowerCase();
        }
      }
      return '';
    };

    if (searchFilter === 'all') {
      return categories.filter(category => {
        const eventName = getEventName(category.key);
        const eventDate = getEventDate(category.key);
        return eventName.includes(searchTermLower) ||
          eventDate.includes(searchTermLower);
      });
    } else if (searchFilter === 'album') {
      return categories.filter(category => {
        const eventName = getEventName(category.key);
        return eventName.includes(searchTermLower);
      });
    } else if (searchFilter === 'date') {
      return categories.filter(category => {
        const eventDate = getEventDate(category.key);
        return eventDate.includes(searchTermLower);
      });
    }

    return [];
  }, [categories, searchFilter, searchTerm]);

  const getSearchPlaceholder = () => {
    switch (searchFilter) {
      case 'album':
        return 'Search by event name...';
      case 'date':
        return 'Search by date (DD/MM/YYYY)...';
      default:
        return 'Search by name or date...';
    }
  };

  const handleCategoryClick = (category) => {
    navigate('/admin_aries_app/GalleryDetails', { state: { key: category.key } });
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/admin_aries_app/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleHelp = () => {
    const email = 'syserp@aries.res.in';
    const subject = 'Need help With Aries Connect';
    window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}`);
  };

  const drawerContent = (
    <Box sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6" component="div">
          Aries Connect
        </Typography>
      </Box>
      <List sx={{ flexGrow: 1 }}>
        {[
          { text: 'Home', icon: <HomeIcon />, action: () => handleNavigation('/admin_aries_app/Home') },
          { text: 'User Info', icon: <PersonIcon />, action: () => handleNavigation('/admin_aries_app/UserInfo') },
          { text: 'Messages', icon: <MessageIcon />, action: () => handleNavigation('/admin_aries_app/Msg') },
          { text: 'Help', icon: <HelpIcon />, action: handleHelp },
        ].map((item, index) => (
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 + index * 200 }} key={item.text}>
            <StyledListItem button onClick={item.action}>
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          </Grow>
        ))}
      </List>
      <Fade in={true} style={{ transitionDelay: '800ms' }}>
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
            sx={{ backgroundColor: '#ff5309', '&:hover': { backgroundColor: '#e64a00' } }}
          >
            Logout
          </Button>
        </Box>
      </Fade>
    </Box>
  );

  const handleShowMore = () => {
    setShowAllCategories(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setShowAllCategories(false);
  };


  useEffect(() => {
    if (filteredCategories.length > 0) {
      if (showAllCategories) {
        setDisplayedCategories(filteredCategories);
      } else {
        setDisplayedCategories(filteredCategories.slice(0, initialDisplayCount));
      }
    }
  }, [filteredCategories, showAllCategories, initialDisplayCount]);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Gallery
            </Typography>
            {!isMobile && (
              <>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Home')}>Home</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/UserInfo')}>User Info</Button>
                <Button color="inherit" onClick={() => handleNavigation('/admin_aries_app/Msg')}>Messages</Button>
                <Button color="inherit" onClick={handleHelp}>Help</Button>
                <Button color="inherit" onClick={handleLogout}>Logout</Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        <StyledDrawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {drawerContent}
        </StyledDrawer>

        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <FormControlLabel
              control={<Radio checked={searchFilter === 'all'} onClick={() => setSearchFilter('all')} />}
              label="All Search"
            />
            <FormControlLabel
              control={<Radio checked={searchFilter === 'album'} onClick={() => setSearchFilter('album')} />}
              label="Title"
            />

            <FormControlLabel
              control={<Radio checked={searchFilter === 'date'} onClick={() => setSearchFilter('date')} />}
              label="Event Date"
            />

          </Box>

          <TextField
            fullWidth
            variant="outlined"
            placeholder={getSearchPlaceholder()}
            InputProps={{
              startAdornment: <SearchIcon color="action" />,
            }}
            onChange={handleSearch}
            sx={{ mb: 3 }}
          />

          {isLoading ? (
            <Typography>Loading categories...</Typography>
          ) : (
            <>
              <Grid container spacing={2}>
                {displayedCategories.map((category, index) => (
                  <Grid item xs={6} sm={4} key={index}>
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 500 + index * 100 }}>
                      <Card
                        onClick={() => handleCategoryClick(category)}
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          '&:hover': {
                            transform: 'scale(1.05)',
                            transition: 'transform 0.3s ease-in-out',
                          },
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image={category.imageUrl}
                            alt={category.key.split("/").slice(1).join("/")}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="div" noWrap>
                              {category.key.split("/")[1].includes("event_name=")
                                ? category.key
                                  .split("/")[1]
                                  .split("event_name=")[1]
                                  .split("event_date=")[0]
                                  .trim()
                                : category.key.split("/")[1]
                              }
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                              <Typography variant="caption" color="text.secondary">
                                {category.key.includes("event_date=")
                                  ? (() => {
                                    const dateMatch = category.key.match(/event_date=([^/]+)/);
                                    if (dateMatch) {
                                      const dateStr = dateMatch[1];
                                      const date = new Date(dateStr);
                                      return date.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric'
                                      });
                                    }
                                    return formatDate(category.lastModified);
                                  })()
                                  : formatDate(category.lastModified)
                                }
                              </Typography>

                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PhotoLibraryIcon fontSize="small" sx={{ mr: 0.5 }} />
                                <Typography variant="caption" color="text.secondary">
                                  {category.imageCount}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grow>
                  </Grid>
                ))}
              </Grid>
              {!showAllCategories && filteredCategories.length > displayedCategories.length && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowMore}
                    sx={{
                      borderRadius: '20px',
                      padding: '10px 20px',
                      fontWeight: 'bold',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
                      },
                    }}
                  >
                    Load More
                  </Button>
                </Box>
              )}
            </>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Gallery;